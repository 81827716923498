/* @import url('../../styles/vitals.css'); */
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

.section-4 {
  background-color: #079ba9;
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);

  font-family: 'Kumbh Sans', sans-serif;
}

.div-block {
  max-width: 1400px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: rgba(7, 155, 169, 0);
  flex-direction: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
}
.form-block {
  width: 100%;
  margin-bottom: 0;
}

.w-form {
  margin: 0 0 15px;
}

.form {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.select-field-2 {
  min-height: 50px;
  color: var(--secondary-1);
  border-radius: 10px;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  /* margin-bottom: 10px; */
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
}
.w-input:focus,
.w-select:focus {
  border-color: #3898ec;
  outline: 0;
}
.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
.w-button[disabled],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
}
.w-input[disabled]:not(.w-input-disabled),
.w-select[disabled]:not(.w-input-disabled),
.w-input[readonly],
.w-select[readonly],
fieldset[disabled]:not(.w-input-disabled) .w-input,
fieldset[disabled]:not(.w-input-disabled) .w-select {
  background-color: #eeeeee;
}
textarea.w-input,
textarea.w-select {
  height: auto;
}
.w-select {
  background-color: #f3f3f3;
}
.w-select[multiple] {
  height: auto;
}
.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  /* border-radius: 0; */
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.submit-button {
  background-color: var(--secondary-3);
  border-radius: 10px;
  font-size: 18px;
}

input.w-button {
  -webkit-appearance: button;
}

button.w-button {
  -webkit-appearance: button;
}

.w-form-done {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}
.w-form-fail {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}
.text-field {
  height: 50px;
  border-radius: 10px;
  margin-bottom: 0;
}

.select-container {
  position: relative;
  width: 100%;
}

.select-container select {
  padding-right: 50px;
  appearance: none;
}

.select-container i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 25px;
}

@media screen and (min-width: 1440px) {
  .div-block {
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .form-block {
    width: 100%;
    margin-bottom: 0;
  }

  .form {
    width: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-items: center;
    display: flex;
  }

  .select-field-2 {
    color: var(--secondary-1);
    flex: 0 auto;
    padding-right: 20px;
    font-size: 16px;
  }

  .text-field {
    min-height: 50px;
    margin-bottom: 0;
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .form-block {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 10px;
    padding-top: 20px;
    padding-left: 10px;
  }

  .form {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .select-field-2 {
    width: 100%;
  }

  .w-input:-moz-placeholder,
  .w-select:-moz-placeholder {
    color: #999;
  }
}

.w-input-error {
  border: var(--crimson) solid 2px;
}
