.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}
.button-primary-header {
  background-color: #024d6d;
  color: white;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 1000px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
  transition: transform 0.3s, background-color 0.3s;
}

.button-primary-header:hover {
  background-color: #0f437f;
  color: white;
  transform: scale3d(0.96, 0.96, 1.01);
}

.button-primary-header.button-2-buttons {
  background-color: #079ba9;
  margin: 32px 0 20px;
  padding-right: 40px;
}

.button-secondary {
  background-color: var(--neutral-300);
  color: var(--neutral-700);
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 1000px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
  transition: transform 0.3s, background-color 0.3s;
}

.button-secondary:hover {
  background-color: var(--neutral-400);
  color: var(--neutral-700);
  transform: scale3d(0.96, 0.96, 1.01);
}
.button-secondary.button-2-buttons {
  color: var(--dark-slate-grey);
  margin-top: 32px;
  margin-bottom: 20px;
  /* margin-right: 68px; */
}

.button-secondary-header {
  background-color: var(--neutral-300);
  color: var(--neutral-700);
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 1000px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
  transition: transform 0.3s, background-color 0.3s;
}

.button-secondary-header:hover {
  background-color: var(--neutral-400);
  color: var(--neutral-700);
  transform: scale3d(0.96, 0.96, 1.01);
}
.button-secondary-header.button-2-buttons {
  color: var(--dark-slate-grey);
  margin-top: 32px;
  margin-bottom: 20px;
  margin-right: 68px;
}

.button-primary-header.button-white {
  background-color: var(--neutral-100);
  color: var(--neutral-800);
}

.button-primary-header.button-white:hover {
  background-color: var(--secondary-1);
}
.button-primary-header.newsletter {
  padding: 16px 32px;
  position: absolute;
  right: 17px;
}
.button-primary-header.button-2-buttons-copy {
  background-color: var(--cadet-blue);
  margin: 32px 0 20px;
  padding-right: 40px;
}
.submit-button {
  background-color: var(--secondary-3);
  border-radius: 10px;
}
.button-secondary-header.hidden.header {
  margin-top: 32px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1440px) {
  .button-primary-header.button-2-buttons {
    margin-right: 20px;
  }
}

@media screen and (max-width: 991px) {
  .button-primary-header.button-2-buttons {
    margin-right: 10px;
  }
  .button-secondary-header.hidden {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .button-primary-header {
    padding-left: 30px;
    padding-right: 30px;
  }
  .button-primary-header.button-2-buttons {
    display: block;
  }

  .button-secondary {
    padding-left: 30px;
    padding-right: 30px;
  }

  .button-secondary.button-2-buttons {
    display: block;
  }

  .button-secondary-header {
    padding-left: 30px;
    padding-right: 30px;
    display: none;
  }

  .button-secondary-header.button-2-buttons {
    display: block;
  }

  .button-primary-header.button-2-buttons-copy {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .button-primary-header {
    padding: 20px 18px;
    display: block;
  }

  .button-primary-header.button-2-buttons {
    margin-bottom: -22px;
    margin-right: 0;
    display: none;
  }
  .button-secondary {
    padding: 20px 18px;
    display: block;
  }
  .button-secondary.button-2-buttons {
    margin-right: 0;
    display: none;
  }
  .button-secondary-header {
    padding: 20px 18px;
    display: none;
  }
  .button-secondary-header.button-2-buttons {
    margin-right: 0;
    display: none;
  }
  .button-primary-header.newsletter {
    min-height: 65px;
    background-color: var(--neutral-700);
    position: static;
  }
}
