@font-face {
  font-family: Rozanovageo;
  src: url('../../styles/fonts/RozanovaGEO-Demo-SemiBold.otf');
}
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

.section {
  padding-top: 80px;
  padding-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;
  color: var(--neutral-600);

  font-size: 18px;

  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}
.container-default {
  max-width: 1209px;
  flex: 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.story-wrapper {
  justify-content: space-between;
  display: flex;
  position: relative;
}
.split-content.story-left {
  z-index: 1;
  max-width: 552px;
  margin-right: 30px;
  position: relative;
}
.split-content.story-right {
  z-index: 1;
  max-width: 552px;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 52px;
  display: flex;
  position: relative;
}
.image-wrapper {
  overflow: hidden;
  transform: translate(0);
}
.image-wrapper.story,
.image-wrapper.mission {
  border-radius: 20px;
}

.image-wrapper.story.hidden {
  display: none;
}

.story-content {
  max-width: 537px;
  margin-top: 170px;
}

.story-content {
  max-width: 537px;
  margin-top: 170px;
}

.title.story {
  color: var(--cadet-blue);
  font-family: 'Rozanovageo', sans-serif;

  font-weight: 600;
  font-size: 38px;
}

.paragraph.about-hero,
.paragraph.achievement,
.paragraph.mission,
.paragraph.story {
  margin-bottom: 0;
}

.mission-content {
  max-width: 537px;
  margin-bottom: 141px;
}
.title.mission {
  max-width: 430px;
  color: var(--cadet-blue);
  font-family: 'Rozanovageo', sans-serif;

  font-weight: 600;
  font-size: 38px;
}

.bg-story-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.bg.story-shape {
  width: 494px;
  min-height: 494px;
  min-width: 494px;
  background-color: var(--secondary-2);
  border-radius: 1000px;
  bottom: 250px;
}

@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }
  .container-default {
    flex-direction: column;
    align-items: stretch;
  }

  .story-wrapper {
    flex-direction: column-reverse;
    align-items: center;
  }
  .split-content.story-left {
    max-width: 754px;
    flex-direction: column-reverse;
    align-items: center;
    margin-right: 0;
    display: flex;
  }
  .split-content.story-right {
    max-width: 754px;
    align-items: center;
    margin-top: 0;
    margin-bottom: 100px;
  }

  .story-content {
    text-align: center;
    margin-top: 0;
    margin-bottom: 50px;
  }
  .mission-content {
    text-align: center;
    margin-bottom: 50px;
  }
  .title.mission {
    margin-left: auto;
    margin-right: auto;
  }
  .bg-story-wrapper {
    display: none;
  }
  .bg.story-shape {
    bottom: auto;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding-top: 118px;
    padding-bottom: 118px;
  }
  .split-content.story-right {
    margin-bottom: 83px;
  }
  .story-content,
  .mission-content,
  .container-small-568px.values {
    margin-bottom: 40px;
  }
  .title.mission, .title.story {
    font-size: 32px;
  }
}
@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
  .container-default {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }

  .split-content.story-right {
    margin-bottom: 69px;
  }
  .story-content,
  .mission-content {
    text-align: left;
    margin-bottom: 35px;
  }

  .title.mission {
    max-width: 313px;
    margin-left: 0;
    font-size: 26px;
  }

  .title.story {
    margin-bottom: 10px;
    font-size: 26px;
  }
}
