@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}

.HomeHero_w-container__ann5q {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.HomeHero_w-container__ann5q:before,
.HomeHero_w-container__ann5q:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}

.HomeHero_section__YfV5e {
  padding-top: 80px;
  padding-bottom: 0;

  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
  color: var(--neutral-600);

  font-family: 'Kumbh Sans', sans-serif;

  font-size: 18px;
}
.HomeHero_container-default__eE7MO {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.HomeHero_section__YfV5e.HomeHero_home-hero__kulCp {
  padding-top: 127px;
  padding-bottom: 127px;
  position: relative;
  overflow: hidden;
}
.HomeHero_home-hero-wrapper__oB689 {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}
.HomeHero_home-hero-wrapper__oB689 {
  text-align: center;
  flex-direction: column;
}

.HomeHero_home-hero-wrapper__oB689 {
  align-items: stretch;
}

.HomeHero_text-span__RtuuR {
  color: #024d6d;
  font-size: 40px;
  font-style: normal;
}

.HomeHero__2-buttons__uSX3x {
  color: #db2a47;
}

.HomeHero_image-wrapper__3lzdk {
  overflow: hidden;
  transform: translate(0);
}
.HomeHero_image-wrapper__3lzdk.HomeHero_home-hero__kulCp {
  max-width: 47%;
  border-radius: 33px;
}
.HomeHero_image__Es8Lb.HomeHero_home-hero__kulCp {
  transition: opacity 0.2s;
}
.HomeHero_paragraph___OI_3.HomeHero_home-hero__kulCp {
  color: #62778f;
  margin-bottom: 56px;
  text-align: start;
}
.HomeHero_title__X7rIF.HomeHero_home-hero__kulCp {
  color: var(--cadet-blue);
  text-align: justify;
  font-family: 'Rozanovageo', sans-serif;

  font-size: 45px;
  font-weight: 600;
}
.HomeHero_split-content__JQzxP.HomeHero_home-hero-content__fW_H9 {
  max-width: 46%;
  min-width: 483px;
  margin-right: 30px;
}

.HomeHero_image__Es8Lb.HomeHero_home-hero__kulCp {
  transition: opacity 0.2s;
}

.HomeHero_home-hero-wrapper__oB689 {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: row;
}

.HomeHero_bg__sQ2VS {
  position: absolute;
}

.HomeHero_bg__sQ2VS.HomeHero_home-hero__kulCp {
  z-index: -2;
  width: 39%;
  background-color: #064ea4;
  top: 0;
  bottom: 0;
  right: 0;
}

.HomeHero_bg__sQ2VS.HomeHero_home-hero-shape-1__mgeIT {
  z-index: -1;
  width: 640px;
  min-height: 640px;
  min-width: 640px;
  background-color: #fcdf69;
  border-radius: 1000px;
  top: -464px;
  left: -559px;
}

.HomeHero_bg__sQ2VS.HomeHero_home-hero-shape-2__oqSYs {
  z-index: -1;
  width: 645px;
  min-height: 645px;
  min-width: 645px;
  background-color: #f99d77;
  border-radius: 1000px;
  bottom: -507px;
  right: -474px;
}
@media screen and (max-width: 1119px ) and (min-width: 992px) {
  .HomeHero__2-buttons__uSX3x {
    display: flex;
    flex-direction: column;
  }
  .HomeHero__2-buttons__uSX3x > a {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
}

/* ///////////////////media queries */
@media screen and (min-width: 1440px) {
  .HomeHero_section__YfV5e.HomeHero_home-hero__kulCp {
    padding-top: 60px;
  }
}

@media screen and (max-width: 991px) {
  .HomeHero_w-container__ann5q {
    max-width: 728px;
  }
  .HomeHero_container-default__eE7MO {
    flex-direction: column;
    align-items: stretch;
  }

  .HomeHero_section__YfV5e.HomeHero_home-hero__kulCp {
    padding-top: 60px;
    padding-bottom: 102px;
  }
  .HomeHero_home-hero-wrapper__oB689 {
    text-align: center;
    flex-direction: column;
  }

  .HomeHero_split-content__JQzxP.HomeHero_home-hero-content__fW_H9 {
    max-width: 535px;
    min-width: auto;
    margin-bottom: 40px;
    margin-right: 0;
  }
  .HomeHero_title__X7rIF.HomeHero_home-hero__kulCp {
    max-width: 482px;
    margin-left: auto;
    margin-right: auto;
  }
  .HomeHero_paragraph___OI_3.HomeHero_home-hero__kulCp {
    margin-bottom: 50px;
  }
  .HomeHero_image-wrapper__3lzdk.HomeHero_home-hero__kulCp {
    max-width: 551px;
  }

  .HomeHero_bg__sQ2VS.HomeHero_home-hero__kulCp {
    width: 99%;
    min-height: 49%;
    top: auto;
    left: -1;
  }
}

@media screen and (max-width: 767px) {
  .HomeHero_split-content__JQzxP.HomeHero_home-hero-content__fW_H9,
  .HomeHero_split-content__JQzxP.HomeHero_featured-teacher-left__wOvYx {
    margin-bottom: 30px;
  }
  .HomeHero_section__YfV5e.HomeHero_home-hero__kulCp {
    padding-top: 81px;
    padding-bottom: 81px;
  }

  .HomeHero_split-content__JQzxP.HomeHero_home-about-content__kOQ50 {
    width: 100%;
    max-width: 552px;
    margin-bottom: 50px;
  }
  .HomeHero_title__X7rIF.HomeHero_home-hero__kulCp {
    margin-bottom: 16px;
  }
  .HomeHero_paragraph___OI_3.HomeHero_home-hero__kulCp {
    max-width: 90%;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 479px) {
  .HomeHero_w-container__ann5q {
    max-width: none;
  }
  .HomeHero_container-default__eE7MO {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }
  .HomeHero_section__YfV5e.HomeHero_home-hero__kulCp {
    padding-top: 65px;
    padding-bottom: 65px;
  }

  .HomeHero_home-hero-wrapper__oB689 {
    align-items: stretch;
  }
  .HomeHero_title__X7rIF.HomeHero_home-hero__kulCp {
    max-width: 358px;
    margin-bottom: 10px;
  }
  .HomeHero_paragraph___OI_3.HomeHero_home-hero__kulCp {
    max-width: 402px;
    margin-bottom: 33px;
    text-align: center;
  }
  .HomeHero__2-buttons__uSX3x {
    flex-direction: column;
    display: flex;
  }
  .HomeHero_image-wrapper__3lzdk.HomeHero_home-hero__kulCp {
    border-radius: 30px;
  }
  .HomeHero_bg__sQ2VS.HomeHero_home-hero__kulCp {
    min-height: 30%;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}

.perks_w-container__8VlUB {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.perks_w-container__8VlUB:before,
.perks_w-container__8VlUB:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.perks_w-container__8VlUB:after {
  clear: both;
}
.perks_w-layout-grid__9cw_3 {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.perks_section__MuePT {
  padding-top: 80px;
  padding-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;

  font-size: 18px;

  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}

.perks_bg-neutral-700__ouAfp {
  background-color: var(--neutral-700);
}
.perks_section__MuePT.perks_bg-neutral-700__ouAfp.perks_perks__Ugbvh {
  background-color: var(--neutral-100);
  padding-top: 81px;
  padding-bottom: 111px;
  position: relative;
}
.perks_container-default__cbEd0 {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.perks_perks-wrapper__cbiIc {
  text-align: center;
}
.perks_title__d7sw2.perks_perks__Ugbvh {
  color: var(--dark-slate-grey);
  margin-bottom: 58px;
  font-family: 'Rozanovageo', sans-serif;

  font-weight: 600;
  font-size: 38px;
}
.perks_perks-grid__nZ243 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-rows: auto;
  grid-template-columns: minmax(auto, 1fr) auto;
  grid-auto-flow: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.perks_image__aUr1w.perks_perk__hZ7tM {
  margin-bottom: 39px;
}
.perks_title__d7sw2.perks_perk__hZ7tM {
  color: var(--dark-slate-grey);

  font-weight: 700;
  font-size: 24px;
}
.perks_paragraph__GQGeE.perks_perk__hZ7tM {
  color: var(--dark-slate-grey);
  margin-bottom: 0;
}
.perks_perk-wrapper__mGeky {
  max-width: 340px;
}
.perks_bg__8O8fF {
  position: absolute;
}
.perks_bg__8O8fF.perks_arrow-wrapper__YVO08 {
  justify-content: center;
  display: flex;
  bottom: -116px;
  left: 0;
  right: 0;
}

@media screen and (max-width: 991px) {
  .perks_w-container__8VlUB {
    max-width: 728px;
  }
  .perks_perks-grid__nZ243 {
    grid-row-gap: 60px;
    flex-direction: column;
    grid-template-columns: 1fr;
  }
  .perks_perk-wrapper__mGeky {
    max-width: none;
    text-align: left;
    justify-content: center;
    align-items: center;
    display: flex;
    grid-column-gap: 30px;
    -moz-column-gap: 30px;
         column-gap: 30px;
  }
  .perks_image__aUr1w.perks_perk__hZ7tM {
    width: 100%;
    max-width: 230px;
    margin-bottom: 0;
    margin-right: 30px;
  }
  .perks_perk-content__L4zx7 {
    max-width: 540px;
  }

  .perks_bg__8O8fF.perks_arrow-wrapper__YVO08 {
    bottom: -100px;
  }

  .perks_image__aUr1w.perks_bg-arrow__UQF_W {
    width: 65px;
  }
}

@media screen and (max-width: 767px) {
  .perks_section__MuePT.perks_bg-neutral-700__ouAfp.perks_perks__Ugbvh {
    padding-bottom: 120px;
    display: none;
}
  .perks_title__d7sw2.perks_perks__Ugbvh {
    margin-bottom: 40px;
  }
  .perks_perks-grid__nZ243 {
    grid-row-gap: 50px;
  }
  .perks_perk-wrapper__mGeky {
    text-align: center;
    flex-direction: column;
  }
  .perks_image__aUr1w.perks_perk__hZ7tM {
    max-width: 192px;
    margin-bottom: 30px;
    margin-right: 0;
  }
  .perks_title__d7sw2.perks_perk__hZ7tM {
    margin-bottom: 10px;
  }
  .perks_perk-wrapper__mGeky {
    text-align: center;
    flex-direction: column;
  }
  .perks_bg__8O8fF.perks_arrow-wrapper__YVO08 {
    bottom: -83px;
  }
  .perks_image__aUr1w.perks_bg-arrow__UQF_W {
    width: 55px;
  }
}
@media screen and (max-width: 479px) {
  .perks_w-container__8VlUB {
    max-width: none;
  }
  .perks_container-default__cbEd0 {
    max-width: 1209px;
    flex: 1 1;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .perks_perk-wrapper__mGeky {
    text-align: center;
    flex-direction: column;
  }

  .perks_image__aUr1w.perks_perk__hZ7tM {
    max-width: 185px;
  }
  .perks_title__d7sw2.perks_perk__hZ7tM,
  .perks_title__d7sw2.perks_card-popular-course__QSv0w,
  .perks_title__d7sw2.perks_card-courses-category__u63_Q {
    margin-bottom: 6px;
  }
  .perks_perk-wrapper__mGeky {
    text-align: center;
    flex-direction: column;
  }
  .perks_bg__8O8fF.perks_arrow-wrapper__YVO08 {
    bottom: -79px;
  }
  .perks_image__aUr1w.perks_bg-arrow__UQF_W {
    width: 45px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}
.Features_w-container__kWDsk {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.Features_w-container__kWDsk:before,
.Features_w-container__kWDsk:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.Features_w-container__kWDsk:after {
  clear: both;
}

.Features_section__qsVWy {
  padding-top: 80px;
  padding-bottom: 0;

  font-size: 18px;
  font-family: 'Kumbh Sans', sans-serif;
  color: var(--neutral-600);

  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}

.Features_section__qsVWy.Features_featured-teacher__fZ1xH {
  padding-top: 60px;
  padding-bottom: 80px;
}

.Features_container-default__t__hJ {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.Features_featured-teacher-wrapper__i8sIR {
  justify-content: center;
  align-items: center;
  display: flex;
}
.Features_split-content__DVNjD.Features_featured-teacher-left__hepoE {
  max-width: 47%;
  margin-right: 30px;
}

.Features_heading-2__7BiSc {
  color: var(--dark-slate-grey);
  font-family: 'Rozanovageo', sans-serif;

  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 16px;

  font-size: 38px;
  font-weight: 600;
  line-height: 1.289em;
}

.Features_paragraph__ESUDz.Features_featured-teacher__fZ1xH {
  margin-bottom: 35px;
}

.Features__2-buttons__PdfMp {
  color: var(--crimson);
}
.Features_split-content__DVNjD.Features_featured-teacher-right__3O0Ts {
  max-width: 46%;
}
.Features_image-wrapper__nYCUA {
  overflow: hidden;
  transform: translate(0);
}
.Features_image-wrapper__nYCUA.Features_featured-teacher__fZ1xH {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;

  margin-left: 20px;
}

.Features_image__LP9aU.Features_featured-teacher__fZ1xH {
  display: block;
}

.Features_card__l0JLo {
  background-color: var(--neutral-100);
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  border-radius: 20px;
  overflow: hidden;
}

.Features_card__l0JLo.Features_featured-teacher__fZ1xH {
  z-index: 1;
  max-width: 89%;
  border: 1px solid var(--neutral-400);
  background-color: var(--neutral-200);
  box-shadow: 13px 20px 38px 11px var(--card-default-shadow-long);
  flex-direction: column;
  align-items: flex-start;
  margin-top: -63px;
  margin-left: auto;
  margin-right: -92px;
  padding: 45px 40px 40px;
  display: flex;
  position: relative;
}

.Features_card-featured-teacher-badge__4Uc9S {
  background-color: var(--cadet-blue);
  color: var(--neutral-100);
  border-radius: 1000px;
  margin-bottom: 24px;
  padding: 12px 20px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.143em;
}
.Features_title__HyHJB {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.Features_title__HyHJB.Features_card-featured-teacher__tLJgH {
  color: var(--dark-slate-grey);
  margin-bottom: 18px;
  color: var(--neutral-700);

  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5em;
}

.Features_card-featured-teacher-name__M8KoN {
  color: var(--dark-slate-grey);
  margin-bottom: 11px;
  font-weight: 700;
  line-height: 1.111em;
}
.Features_card-featured-teacher-rol__moOPC {
  letter-spacing: -0.02em;
  font-size: 16px;
  line-height: 1.125em;
}

.Features__2-buttons__PdfMp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 1439px) {
}
@media screen and (max-width: 991px) {
  .Features_w-container__kWDsk {
    max-width: 728px;
  }
  .Features_section__qsVWy.Features_featured-teacher__fZ1xH {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .Features_container-default__t__hJ {
    flex-direction: column;
    align-items: stretch;
  }

  .Features_featured-teacher-wrapper__i8sIR {
    text-align: center;
    flex-direction: column;
  }
  .Features_split-content__DVNjD.Features_featured-teacher-left__hepoE {
    max-width: 542px;
    margin-bottom: 40px;
    margin-right: 0;
  }

  .Features_split-content__DVNjD.Features_featured-teacher-right__3O0Ts {
    max-width: 538px;
  }
  .Features__2-buttons__PdfMp {
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .Features_section__qsVWy.Features_featured-teacher__fZ1xH {
    padding-bottom: 138px;
  }
  .Features_split-content__DVNjD.Features_featured-teacher-left__hepoE {
    margin-bottom: 30px;
  }
  .Features_paragraph__ESUDz.Features_featured-teacher__fZ1xH {
    margin-bottom: 30px;
  }

  .Features_card__l0JLo.Features_featured-teacher__fZ1xH {
    max-width: 90%;
    margin-top: -120px;
    padding: 38px 33px 33px;
    display: none;
  }
  .Features_card-featured-teacher-badge__4Uc9S {
    margin-bottom: 20px;
  }

  .Features_heading-2__7BiSc {
    font-size: 32px;
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 479px) {
  .Features_w-container__kWDsk {
    max-width: none;
  }
  .Features_section__qsVWy.Features_featured-teacher__fZ1xH {
    padding-bottom: 111px;
  }

  .Features_container-default__t__hJ {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }
  .Features_heading-2__7BiSc {
    margin-bottom: 10px;
    font-size: 26px;
  }
  .Features__2-buttons__PdfMp {
    flex-direction: column;
    display: flex;
  }
  .Features_card__l0JLo.Features_featured-teacher__fZ1xH {
    max-width: 95%;
    margin-top: -70px;
    padding: 32px 25px 28px;
  }

  .Features_card-featured-teacher-badge__4Uc9S {
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Newsreader:ital,opsz,wght@0,6..72,200..800;1,6..72,200..800&display=swap');

@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}

.ctaSection_w-container__9J7zU {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.ctaSection_w-container__9J7zU:before,
.ctaSection_w-container__9J7zU:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.ctaSection_w-container__9J7zU:after {
  clear: both;
}

.ctaSection_section__VEaaN {
  padding-top: 80px;
  padding-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;

  font-size: 18px;

  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}

.ctaSection_section__VEaaN.ctaSection_cta__S7VBn {
  background-color: var(--cadet-blue);
  padding-top: 88px;
  padding-bottom: 88px;
  overflow: hidden;
}

.ctaSection_container-default__ASfOC {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.ctaSection_cta-wrapper__5i7Jz {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.ctaSection_split-content__Ri6rQ.ctaSection_cta-left__195pR {
  z-index: 1;
  margin-right: 30px;
  position: relative;
}

.ctaSection_title__a1sfN.ctaSection_cta__S7VBn {
  max-width: 520px;
  color: var(--neutral-100);
  font-family: 'Rozanovageo', sans-serif;

  /* color: var(--neutral-800); */
  margin-top: 0;
  margin-bottom: 16px;

  font-size: 38px;
  font-weight: 600;
  line-height: 1.289em;
}

.ctaSection_paragraph__uMQOo.ctaSection_cta__S7VBn {
  max-width: 520px;
  color: var(--neutral-300);
  margin-bottom: 38px;
}

.ctaSection_split-content__Ri6rQ.ctaSection_cta-right__Bf9Pn {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}
.ctaSection_cta-cards-left__CV0Gh {
  z-index: 1;
  margin-right: 20px;
  position: relative;
}
.ctaSection_card__TEUiX {
  background-color: var(--neutral-100);
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  border-radius: 20px;
  overflow: hidden;
}

.ctaSection_card__TEUiX.ctaSection_cta__S7VBn {
  max-width: 235px;
  text-align: center;
  margin-bottom: 20px;
  padding: 49px 26px 41px;
}
.ctaSection_card-cta-number__dkOFa {
  color: var(--neutral-700);
  margin-bottom: 8px;
  font-family: Newsreader, sans-serif;
  font-size: 54px;
  font-weight: 600;
  line-height: 1.037em;
}
.ctaSection_paragraph__uMQOo.ctaSection_card-cta__5piAm {
  margin-bottom: 0;
}
.ctaSection_card__TEUiX.ctaSection_cta__S7VBn.ctaSection_last__O7iVD {
  margin-bottom: 0;
}
.ctaSection_cta-cards-right__LHN5l {
  z-index: 1;
  position: relative;
}
.ctaSection_bg__krced {
  position: absolute;
}
.ctaSection_bg__krced.ctaSection_cta-shape-1__6Bl9o {
  width: 356px;
  min-height: 356px;
  min-width: 356px;
  background-color: var(--secondary-2);
  border-radius: 1000px;
  top: -255px;
  left: -340px;
}
.ctaSection_bg__krced.ctaSection_cta-shape-2__i9EbG {
  width: 336px;
  min-height: 336px;
  min-width: 336px;
  background-color: var(--secondary-1);
  border-radius: 1000px;
  bottom: -340px;
  left: 329px;
}

.ctaSection_bg__krced.ctaSection_cta-shape-3__lLVPl {
  width: 335px;
  min-height: 335px;
  min-width: 335px;
  background-color: var(--secondary-3);
  border-radius: 1000px;
}

@media screen and (max-width: 991px) {
  .ctaSection_w-container__9J7zU {
    max-width: 728px;
  }

  .ctaSection_section__VEaaN.ctaSection_cta__S7VBn {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .ctaSection_container-default__ASfOC {
    flex-direction: column;
    align-items: stretch;
  }

  .ctaSection_cta-wrapper__5i7Jz {
    text-align: center;
    flex-direction: column;
  }

  .ctaSection_split-content__Ri6rQ.ctaSection_cta-left__195pR {
    margin-bottom: 60px;
    margin-right: 0;
  }
  .ctaSection_paragraph__uMQOo.ctaSection_cta__S7VBn {
    margin-bottom: 35px;
  }

  .ctaSection_bg__krced.ctaSection_cta-shape-1__6Bl9o {
    left: -270px;
  }

  .ctaSection_bg__krced.ctaSection_cta-shape-2__i9EbG {
    left: auto;
    right: -33px;
  }
}

@media screen and (max-width: 767px) {
  .ctaSection_section__VEaaN.ctaSection_cta__S7VBn {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .ctaSection_split-content__Ri6rQ.ctaSection_cta-left__195pR {
    margin-bottom: 50px;
  }
  .ctaSection_paragraph__uMQOo.ctaSection_cta__S7VBn {
    margin-bottom: 30px;
  }

  .ctaSection_card-cta-number__dkOFa {
    font-size: 45px;
  }
  .ctaSection_bg__krced.ctaSection_cta-shape-1__6Bl9o {
    width: 285px;
    min-height: 285px;
    min-width: 285px;
    top: 125px;
    left: -284px;
  }

  .ctaSection_bg__krced.ctaSection_cta-shape-2__i9EbG {
    width: 268px;
    min-height: 268px;
    min-width: 268px;
    bottom: -289px;
  }

  .ctaSection_bg__krced.ctaSection_cta-shape-3__lLVPl {
    width: 268px;
    min-height: 268px;
    min-width: 268px;
  }
  .ctaSection_title__a1sfN.ctaSection_cta__S7VBn {
    margin-bottom: 16px;
    font-size: 32px;
  }
}

@media screen and (max-width: 479px) {
  .ctaSection_w-container__9J7zU {
    max-width: none;
  }
  .ctaSection_container-default__ASfOC {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }
  .ctaSection_title__a1sfN.ctaSection_cta__S7VBn {
    margin-bottom: 10px;
    font-size: 26px;
  }
  .ctaSection_cta-wrapper__5i7Jz {
    align-items: stretch;
  }

  .ctaSection_split-content__Ri6rQ.ctaSection_cta-right__Bf9Pn {
    flex-direction: column;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
  }

  .ctaSection_cta-cards-left__CV0Gh {
    margin-bottom: 20px;
    margin-right: 0;
  }

  .ctaSection_card__TEUiX.ctaSection_cta__S7VBn {
    max-width: 100%;
    padding: 40px 25px 34px;
  }

  .ctaSection_card-cta-number__dkOFa {
    margin-bottom: 6px;
    font-size: 38px;
  }

  .ctaSection_bg__krced.ctaSection_cta-shape-1__6Bl9o {
    width: 228px;
    min-height: 228px;
    min-width: 228px;
    top: 263px;
    left: -213px;
  }

  .ctaSection_bg__krced.ctaSection_cta-shape-2__i9EbG {
    width: 214px;
    min-height: 214px;
    min-width: 214px;
    bottom: -229px;
  }

  .ctaSection_bg__krced.ctaSection_cta-shape-3__lLVPl {
    width: 214px;
    min-height: 214px;
    min-width: 214px;
    top: 124.5px;
    left: 41px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}

.aboutSection_w-container__W4G_x {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.aboutSection_w-container__W4G_x:before,
.aboutSection_w-container__W4G_x:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.aboutSection_w-container__W4G_x:after {
  clear: both;
}
.aboutSection_section__F43cL {
  padding-top: 80px;
  padding-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;

  color: var(--neutral-600);
  font-size: 18px;
  line-height: 30px;
  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}
.aboutSection_container-small-550px__0XFin {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.aboutSection_container-small-550px__0XFin.aboutSection_home-about__wczkr {
  text-align: center;
  margin-bottom: 55px;
}

.aboutSection_heading__bEq39 {
  color: var(--neutral-800);
  margin-top: 0;
  margin-bottom: 16px;
  font-family: 'Rozanovageo', sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.289em;
}

.aboutSection_paragraph-2__rBa35 {
  text-align: justify;
  padding-right: 0;
  margin-top: 0;
  margin-bottom: 10px;
}

.aboutSection_container-default__mihGk {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.aboutSection_home-about-wrapper__yGXtB {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  display: flex;
}
.aboutSection_image-wrapper__PAhar {
  overflow: hidden;
  transform: translate(0);
}
.aboutSection_image-wrapper__PAhar.aboutSection_home-about__wczkr {
  max-width: 48%;
  border-radius: 20px;
  margin-right: 30px;
}
.aboutSection_image__HI3kJ.aboutSection_icon-home-about__Dr94_ {
  width: 60px;
  height: 60px;
  min-height: 60px;
  min-width: 60px;
  border-radius: 1000px;
  margin-top: 9px;
  margin-right: 33px;
  overflow: hidden;
}

.aboutSection_split-content__iG_5Z.aboutSection_home-about-content__N46zS {
  max-width: 519px;
}

.aboutSection_home-about-details__z_q6B {
  align-items: flex-start;
  margin-bottom: 52px;
  display: flex;
}

.aboutSection_home-about-details__z_q6B.aboutSection_last__hEjjR {
  margin-bottom: 0;
}

.aboutSection_home-about-details-content__XV1mK {
  max-width: 425px;
}

.aboutSection_home-about-details-content__XV1mK {
  max-width: 425px;
}

.aboutSection_title__hJH11.aboutSection_home-about-details-title__Gd7_w {
  margin-bottom: 8px;
  color: var(--neutral-700);
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5em;
  font-family: 'Rozanovageo', sans-serif;
}

.aboutSection_paragraph__5Hetm.aboutSection_home-about-details-paragraph__HdMqy {
  margin-bottom: 0;
}
.aboutSection_paragraph-2__rBa35 {
  text-align: justify;
  padding-right: 0;
}
@media screen and (max-width: 991px) {
  .aboutSection_w-container__W4G_x {
    max-width: 728px;
  }
  .aboutSection_container-small-550px__0XFin.aboutSection_home-about__wczkr {
    margin-bottom: 50px;
  }

  .aboutSection_container-default__mihGk {
    flex-direction: column;
    align-items: stretch;
  }

  .aboutSection_home-about-wrapper__yGXtB {
    flex-direction: column-reverse;
    margin-bottom: 50px;
  }

  .aboutSection_image-wrapper__PAhar.aboutSection_home-about__wczkr {
    max-width: 552px;
    margin-right: 0;
  }
  .aboutSection_split-content__iG_5Z.aboutSection_home-about-content__N46zS {
    margin-bottom: 60px;
  }

  .aboutSection_home-about-details__z_q6B {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) {
  .aboutSection_container-small-550px__0XFin.aboutSection_home-about__wczkr {
    margin-bottom: 40px;
  }
  .aboutSection_home-about-wrapper__yGXtB {
    margin-bottom: 40px;
  }
  .aboutSection_split-content__iG_5Z.aboutSection_home-about-content__N46zS {
    width: 100%;
    max-width: 552px;
    margin-bottom: 50px;
  }
  .aboutSection_home-about-details__z_q6B {
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .aboutSection_home-about-details-content__XV1mK {
    max-width: none;
  }
  .aboutSection_image__HI3kJ.aboutSection_icon-home-about__Dr94_ {
    margin-top: 0;
    margin-bottom: 20px;
    margin-right: 0;
  }
  .aboutSection_home-about-details-content__XV1mK {
    max-width: none;
  }
  .aboutSection_heading__bEq39 {
    margin-bottom: 16px;
    font-size: 32px;
  }
}

@media screen and (max-width: 479px) {
  .aboutSection_w-container__W4G_x {
    max-width: none;
  }
  .aboutSection_container-small-550px__0XFin {
    padding-left: 16px;
    padding-right: 16px;
  }

  .aboutSection_container-default__mihGk {
    max-width: 1209px;
    flex: 1 1;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .aboutSection_home-about-details__z_q6B {
    flex-direction: column;
  }

  .aboutSection_image__HI3kJ.aboutSection_icon-home-about__Dr94_ {
    margin-bottom: 15px;
  }
  .aboutSection_paragraph__5Hetm.aboutSection_home-about-details-paragraph__HdMqy {
    margin-left: 10px;
    margin-right: 10px;
  }
  .aboutSection_heading__bEq39 {
    margin-bottom: 10px;
    font-size: 26px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}

.blogSection_w-container__qHnhi {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.blogSection_w-container__qHnhi:before,
.blogSection_w-container__qHnhi:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.blogSection_w-container__qHnhi:after {
  clear: both;
}
.blogSection_container-default__ebwHU {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.blogSection_w-layout-grid__nd_5p {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.blogSection_w-inline-block__Fq_h8 {
  max-width: 100%;
  display: inline-block;
}
.blogSection_section__b2QtI {
  padding-top: 80px;
  padding-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;

  font-size: 18px;

  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}

.blogSection_section__b2QtI.blogSection_blog-section__r_T5D {
  padding-top: 80px;
  padding-bottom: 80px;
}

.blogSection_content-top__in1q4.blogSection_blog-section__r_T5D {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  display: flex;
}

.blogSection_title__pb8xb.blogSection_blog-section__r_T5D {
  font-family: 'Rozanovageo', sans-serif;
  color: var(--cadet-blue);
  margin-bottom: 0;
  margin-top: 0;
  font-size: 38px;
  font-weight: 600;
  line-height: 1.289em;
}

.blogSection_blog-section-grid__yKacJ {
  grid-column-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

.blogSection_card__e9TBn {
  background-color: var(--neutral-100);
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  border-radius: 20px;
  overflow: hidden;
}

.blogSection_card__e9TBn.blogSection_post-featured-first__SFa1d {
  color: var(--neutral-600);
  transform-style: preserve-3d;
  flex-direction: column;
  text-decoration: none;
  transition: box-shadow 0.3s, transform 0.3s;
  display: flex;
  transform: translate(0);
}

.blogSection_card__e9TBn.blogSection_post-featured-first__SFa1d:hover {
  box-shadow: 0 15px 15px 0 var(--card-default-shadow-short);
  color: var(--neutral-600);
  transform: translate3d(0, -10px, 0.01px);
}
.blogSection_card__e9TBn.blogSection_post-featured-first__SFa1d .blogSection_title__pb8xb {
  transition: all 0.2s ease-in-out;
}
.blogSection_card__e9TBn.blogSection_post-featured-first__SFa1d .blogSection_image__nhMwF {
  transition: all 0.2s linear;
}

.blogSection_card__e9TBn.blogSection_post-featured-first__SFa1d:hover .blogSection_title__pb8xb {
  color: rgb(6, 78, 164);
  transition: all 0.2s ease-in-out;
}
.blogSection_card__e9TBn.blogSection_post-featured-first__SFa1d:hover .blogSection_image__nhMwF {
  transform: scale(1.1);
  transition: all 0.2s linear;
}

.blogSection_image-wrapper__cYrlu {
  overflow: hidden;
  transform: translate(0);
}

.blogSection_image-wrapper__cYrlu.blogSection_card-post-featured-first__q5CRv {
  position: relative;
}

.blogSection_card-post-featured-first-category-wrapper__M4YYn {
  background-color: var(--neutral-100);
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  color: var(--neutral-700);
  border-radius: 1000px;
  align-items: center;
  padding: 14px 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;
  display: flex;
  position: absolute;
  top: 25px;
  right: 32px;
}

.blogSection_image__nhMwF.blogSection_card-post-featured-first-category-icon__kZr_J {
  margin-right: 6px;
}

.blogSection_card-post-featured-first-content__MnV6a {
  padding: 48px 31px 56px;
}

.blogSection_card-post-featured-first-date__v72BF {
  letter-spacing: -0.02em;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
}
.blogSection_title__pb8xb.blogSection_card-post-featured-first__q5CRv,
.blogSection_title__pb8xb.blogSection_card-post-featured__n54RP {
  margin-bottom: 0;

  color: var(--neutral-700);
  margin-top: 0;
  margin-bottom: 16px;

  font-weight: 700;
  line-height: 1.5em;
}

.blogSection_image__nhMwF.blogSection_card-post-featured__n54RP {
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.blogSection_card-post-featured-content__nLr_j {
  width: 100%;
  max-width: 296px;
  padding: 31px 25px 31px 27px;
}

.blogSection_card__e9TBn.blogSection_post-featured__jne6U {
  color: var(--neutral-600);
  transform-style: preserve-3d;
  justify-content: space-between;
  text-decoration: none;
  transition: box-shadow 0.3s, transform 0.3s;
  display: flex;
  transform: translate(0);
}

.blogSection_card__e9TBn.blogSection_post-featured__jne6U:hover {
  box-shadow: 0 15px 15px 0 var(--card-default-shadow-short);
  color: var(--neutral-600);
  transform: translate3d(0, -5px, 0.01px);
}

.blogSection_card__e9TBn.blogSection_post-featured__jne6U .blogSection_title__pb8xb {
  transition: all 0.2s ease-in-out;
}
.blogSection_card__e9TBn.blogSection_post-featured__jne6U .blogSection_image__nhMwF {
  transition: all 0.2s ease-in-out;
}

.blogSection_card__e9TBn.blogSection_post-featured__jne6U:hover .blogSection_title__pb8xb {
  color: rgb(6, 78, 164);
}
.blogSection_card__e9TBn.blogSection_post-featured__jne6U:hover .blogSection_image__nhMwF {
  transform: scale(1.1);
}
.blogSection_post-featured-sidebar-grid__t0FHZ {
  min-height: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 31px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.blogSection_image-wrapper__cYrlu.blogSection_card-post-featured__n54RP {
  width: 100%;
  max-width: 270px;
}

@media screen and (max-width: 991px) {
  .blogSection_w-container__qHnhi {
    max-width: 728px;
  }

  .blogSection_container-default__ebwHU {
    flex-direction: column;
    align-items: stretch;
  }
  .blogSection_section__b2QtI.blogSection_blog-section__r_T5D {
    padding-bottom: 134px;
  }
  .blogSection_content-top__in1q4.blogSection_blog-section__r_T5D {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .blogSection_split-content__PrSIT.blogSection_blog-section-left__b2rGB {
    margin-bottom: 20px;
  }
  .blogSection_blog-section-grid__yKacJ {
    max-width: 567px;
    grid-row-gap: 50px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  .blogSection_section__b2QtI.blogSection_cta__R5IoX {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .blogSection_image-wrapper__cYrlu.blogSection_card-post-featured__n54RP {
    max-width: 100%;
  }
  .blogSection_section__b2QtI.blogSection_blog-section__r_T5D {
    padding-bottom: 107px;
  }

  .blogSection_content-top__in1q4.blogSection_blog-section__r_T5D,
  .blogSection_content-top__in1q4.blogSection_teachers__YRck_ {
    margin-bottom: 40px;
  }

  .blogSection_blog-section-grid__yKacJ {
    grid-row-gap: 40px;
  }

  .blogSection_card-post-featured-first-category-wrapper__M4YYn {
    right: 28px;
  }
  .blogSection_card-post-featured-first-content__MnV6a {
    padding-top: 40px;
    padding-bottom: 47px;
  }
  .blogSection_card-post-featured-content__nLr_j {
    max-width: 100%;
    padding-left: 31px;
    padding-right: 31px;
  }

  .blogSection_title__pb8xb.blogSection_card-post-featured__n54RP {
    max-width: 400px;
  }

  .blogSection_card__e9TBn.blogSection_post-featured__jne6U {
    flex-direction: column;
  }
}

@media screen and (max-width: 479px) {
  .blogSection_w-container__qHnhi {
    max-width: none;
  }
  .blogSection_container-default__ebwHU {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }
  .blogSection_section__b2QtI.blogSection_blog-section__r_T5D {
    padding-bottom: 86px;
  }

  .blogSection_content-top__in1q4.blogSection_blog-section__r_T5D,
  .blogSection_content-top__in1q4.blogSection_teachers__YRck_ {
    align-items: stretch;
  }

  .blogSection_title__pb8xb.blogSection_blog-section__r_T5D {
    text-align: center;
  }

  .blogSection_card-post-featured-first-category-wrapper__M4YYn {
    padding-left: 20px;
    padding-right: 20px;
    right: 20px;
  }

  .blogSection_card-post-featured-first-content__MnV6a {
    padding: 33px 25px 40px;
  }

  .blogSection_card-post-featured-first-date__v72BF {
    margin-bottom: 15px;
  }
  .blogSection_card-post-featured-content__nLr_j {
    padding: 26px 25px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}

.NewsletterSection_w-container__47SR9 {
  margin-left: auto;
  margin-right: auto;
  /* max-width: 940px; */
}
.NewsletterSection_w-container__47SR9:before,
.NewsletterSection_w-container__47SR9:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.NewsletterSection_w-container__47SR9:after {
  clear: both;
}

.NewsletterSection_w-form__xQon_ {
  margin: 0 0 15px;
}
.NewsletterSection_w-form-done__uFq5y {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}
.NewsletterSection_w-form-fail__XYF2I {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}

.NewsletterSection_w-input__qs794,
.NewsletterSection_w-select__Wsm2h {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.NewsletterSection_w-input__qs794:-moz-placeholder,
.NewsletterSection_w-select__Wsm2h:-moz-placeholder {
  color: #999;
}
.NewsletterSection_w-input__qs794::-moz-placeholder,
.NewsletterSection_w-select__Wsm2h::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.NewsletterSection_w-input__qs794::-webkit-input-placeholder,
.NewsletterSection_w-select__Wsm2h::-webkit-input-placeholder {
  color: #999;
}
.NewsletterSection_w-input__qs794:focus,
.NewsletterSection_w-select__Wsm2h:focus {
  border-color: #3898ec;
  outline: 0;
}
.NewsletterSection_w-input__qs794[disabled],
.NewsletterSection_w-select__Wsm2h[disabled],
.NewsletterSection_w-input__qs794[readonly],
.NewsletterSection_w-select__Wsm2h[readonly],
fieldset[disabled] .NewsletterSection_w-input__qs794,
fieldset[disabled] .NewsletterSection_w-select__Wsm2h {
  cursor: not-allowed;
}
.NewsletterSection_w-input__qs794[disabled]:not(.NewsletterSection_w-input-disabled__sO2PF),
.NewsletterSection_w-select__Wsm2h[disabled]:not(.NewsletterSection_w-input-disabled__sO2PF),
.NewsletterSection_w-input__qs794[readonly],
.NewsletterSection_w-select__Wsm2h[readonly],
fieldset[disabled]:not(.NewsletterSection_w-input-disabled__sO2PF) .NewsletterSection_w-input__qs794,
fieldset[disabled]:not(.NewsletterSection_w-input-disabled__sO2PF) .NewsletterSection_w-select__Wsm2h {
  background-color: #eeeeee;
}
textarea.NewsletterSection_w-input__qs794,
textarea.NewsletterSection_w-select__Wsm2h {
  height: auto;
}

.NewsletterSection_w-button__OXle4 {
  display: inline-block;
  padding: 9px 15px;
  background-color: #3898ec;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}
input.NewsletterSection_w-button__OXle4 {
  -webkit-appearance: button;
}

.NewsletterSection_w-form-done__uFq5y {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}

.NewsletterSection_w-form-fail__XYF2I {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}
.NewsletterSection_section__vRNo2 {
  padding-top: 80px;
  padding-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;

  font-size: 18px;

  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}

.NewsletterSection_section__vRNo2.NewsletterSection_cta__dBs5H {
  background-color: var(--cadet-blue);
  padding-top: 88px;
  padding-bottom: 88px;
  overflow: hidden;
}

.NewsletterSection_container-default__dSTnH {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.NewsletterSection_section__vRNo2.NewsletterSection_newsletter__JeeGM {
  margin-bottom: 60px;
  padding-top: 0;
  padding-bottom: 0;
}

.NewsletterSection_newsletter-wrapper__ong7k {
  background-color: var(--primary-1);
  border-radius: 30px;
  justify-content: space-between;
  align-items: center;
  padding: 132px 60px 132px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.NewsletterSection_title__Y1k7A.NewsletterSection_newsletter__JeeGM {
  z-index: 1;
  max-width: 465px;
  min-width: 405px;
  color: var(--neutral-100);
  margin-bottom: 0;
  margin-right: 20px;
  font-family: 'Rozanovageo', sans-serif;

  position: relative;

  margin-top: 0;
  margin-bottom: 16px;

  font-size: 38px;
  font-weight: 600;
  line-height: 1.289em;
}

.NewsletterSection_newsletter-form-block__S_b5g {
  z-index: 1;
  width: 100%;
  max-width: 513px;
  margin-bottom: 0;
  position: relative;
}

.NewsletterSection_newsletter-form__lxBFA {
  align-items: center;
  display: flex;
  position: relative;
}

.NewsletterSection_input__8jyPV {
  min-height: 65px;
  background-color: var(--neutral-200);
  color: var(--neutral-800);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 100px;
  margin-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  line-height: 1.111em;
  transition: border-color 0.3s, color 0.3s;
}

.NewsletterSection_input__8jyPV:hover {
  border-color: var(--neutral-400);
}

.NewsletterSection_input__8jyPV:focus,
.NewsletterSection_input__8jyPV.NewsletterSection_-wfp-focus__KkZqH {
  border-color: var(--neutral-400);
  color: var(--neutral-800);
}

.NewsletterSection_input__8jyPV::-moz-placeholder {
  color: var(--neutral-600);
}

.NewsletterSection_input__8jyPV:-ms-input-placeholder {
  color: var(--neutral-600);
}

.NewsletterSection_input__8jyPV::placeholder {
  color: var(--neutral-600);
}

.NewsletterSection_input__8jyPV.NewsletterSection_newsletter__JeeGM {
  min-height: 82px;
  background-color: var(--neutral-100);
  padding-left: 32px;
  padding-right: 190px;
}

.NewsletterSection_input__8jyPV.NewsletterSection_newsletter__JeeGM::-moz-placeholder {
  color: var(--neutral-500);
}

.NewsletterSection_input__8jyPV.NewsletterSection_newsletter__JeeGM:-ms-input-placeholder {
  color: var(--neutral-500);
}

.NewsletterSection_input__8jyPV.NewsletterSection_newsletter__JeeGM::placeholder {
  color: var(--neutral-500);
}

.NewsletterSection_button-primary-header__4SUpa.NewsletterSection_newsletter__JeeGM {
  padding: 16px 32px;
  position: absolute;
  right: 17px;
}

.NewsletterSection_success-message__oqehE {
  background-color: var(--primary-1);
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  color: var(--neutral-100);
  text-align: center;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.NewsletterSection_success-message__oqehE.NewsletterSection_bg-neutral-800__94k70 {
  background-color: var(--neutral-800);
}

.NewsletterSection_error-message__dQRes {
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  color: var(--neutral-100);
  text-align: center;
  background-color: #fc5457;
  border-radius: 20px;
  align-self: stretch;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.NewsletterSection_bg__jUpRJ {
  position: absolute;
}

.NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-2__KAVAi {
  width: 409px;
  min-height: 409px;
  min-width: 409px;
  background-color: var(--secondary-2);
  border-radius: 1000px;
  top: -129.5px;
  right: -107px;
}

.NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-1__1Ck3v {
  width: 409px;
  min-height: 409px;
  min-width: 409px;
  background-color: var(--secondary-3);
  border-radius: 1000px;
  bottom: -174.5px;
  left: -139px;
}

@media screen and (max-width: 991px) {
  .NewsletterSection_w-container__47SR9 {
    max-width: 728px;
  }

  .NewsletterSection_section__vRNo2.NewsletterSection_cta__dBs5H {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .NewsletterSection_container-default__dSTnH {
    flex-direction: column;
    align-items: stretch;
  }

  .NewsletterSection_newsletter-wrapper__ong7k {
    text-align: center;
    flex-direction: column;
    padding-top: 110px;
    padding-bottom: 110px;
  }

  .NewsletterSection_title__Y1k7A.NewsletterSection_newsletter__JeeGM {
    min-width: auto;
    margin-bottom: 40px;
    margin-right: 0;
  }

  .NewsletterSection_newsletter-form-block__S_b5g {
    max-width: 650px;
  }

  .NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-2__KAVAi {
    top: -279.5px;
    right: -186px;
  }

  .NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-1__1Ck3v {
    left: -80px;
  }

  .NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-1__1Ck3v {
    left: -80px;
  }
}

@media screen and (max-width: 767px) {
  .NewsletterSection_section__vRNo2.NewsletterSection_cta__dBs5H {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .NewsletterSection_newsletter-wrapper__ong7k {
    padding: 92px 40px;
  }

  .NewsletterSection_title__Y1k7A.NewsletterSection_newsletter__JeeGM {
    margin-bottom: 30px;

    margin-bottom: 16px;
    font-size: 32px;
  }

  .NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-2__KAVAi {
    top: -320.5px;
    right: -231px;
  }

  .NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-1__1Ck3v {
    bottom: -253.5px;
    left: -149px;
  }
}

@media screen and (max-width: 479px) {
  .NewsletterSection_w-container__47SR9 {
    max-width: none;
  }
  .NewsletterSection_container-default__dSTnH {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }
  .NewsletterSection_newsletter-wrapper__ong7k {
    padding: 64px 25px;
  }

  .NewsletterSection_newsletter-form__lxBFA {
    flex-direction: column;
    align-items: stretch;
  }

  .NewsletterSection_input__8jyPV {
    padding-left: 15px;
    padding-right: 15px;
  }
  .NewsletterSection_input__8jyPV.NewsletterSection_newsletter__JeeGM {
    min-height: 65px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .NewsletterSection_button-primary-header__4SUpa.NewsletterSection_newsletter__JeeGM {
    min-height: 65px;
    background-color: var(--neutral-700);
    position: static;
  }

  .NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-2__KAVAi {
    top: -339.5px;
  }

  .NewsletterSection_bg__jUpRJ.NewsletterSection_newsletter-shape-1__1Ck3v {
    bottom: -221.5px;
    left: -206px;
  }
  .NewsletterSection_title__Y1k7A.NewsletterSection_newsletter__JeeGM {
    font-size: 26px;
  }
}

/* @import url('../../styles/vitals.css'); */
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

.SearchBar_section-4__1YiVP {
  background-color: #079ba9;
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);

  font-family: 'Kumbh Sans', sans-serif;
}

.SearchBar_div-block__7Zk3D {
  max-width: 1400px;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: rgba(7, 155, 169, 0);
  flex-direction: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
}
.SearchBar_form-block__S_Xs4 {
  width: 100%;
  margin-bottom: 0;
}

.SearchBar_w-form__rcbHi {
  margin: 0 0 15px;
}

.SearchBar_form__hN4X4 {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.SearchBar_select-field-2__DFAL_ {
  min-height: 50px;
  color: var(--secondary-1);
  border-radius: 10px;
  margin-bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}

.SearchBar_w-input__dPBOo,
.SearchBar_w-select__R2K2I {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  /* margin-bottom: 10px; */
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.SearchBar_w-input__dPBOo::-moz-placeholder,
.SearchBar_w-select__R2K2I::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.SearchBar_w-input__dPBOo::-webkit-input-placeholder,
.SearchBar_w-select__R2K2I::-webkit-input-placeholder {
  color: #999;
}
.SearchBar_w-input__dPBOo:focus,
.SearchBar_w-select__R2K2I:focus {
  border-color: #3898ec;
  outline: 0;
}
.SearchBar_w-input__dPBOo[disabled],
.SearchBar_w-select__R2K2I[disabled],
.SearchBar_w-input__dPBOo[readonly],
.SearchBar_w-select__R2K2I[readonly],
.SearchBar_w-button__81kL4[disabled],
fieldset[disabled] .SearchBar_w-input__dPBOo,
fieldset[disabled] .SearchBar_w-select__R2K2I {
  cursor: not-allowed;
}
.SearchBar_w-input__dPBOo[disabled]:not(.SearchBar_w-input-disabled__PXQnC),
.SearchBar_w-select__R2K2I[disabled]:not(.SearchBar_w-input-disabled__PXQnC),
.SearchBar_w-input__dPBOo[readonly],
.SearchBar_w-select__R2K2I[readonly],
fieldset[disabled]:not(.SearchBar_w-input-disabled__PXQnC) .SearchBar_w-input__dPBOo,
fieldset[disabled]:not(.SearchBar_w-input-disabled__PXQnC) .SearchBar_w-select__R2K2I {
  background-color: #eeeeee;
}
textarea.SearchBar_w-input__dPBOo,
textarea.SearchBar_w-select__R2K2I {
  height: auto;
}
.SearchBar_w-select__R2K2I {
  background-color: #f3f3f3;
}
.SearchBar_w-select__R2K2I[multiple] {
  height: auto;
}
.SearchBar_w-button__81kL4 {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  /* border-radius: 0; */
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.SearchBar_submit-button__WPBvN {
  background-color: var(--secondary-3);
  border-radius: 10px;
  font-size: 18px;
}

input.SearchBar_w-button__81kL4 {
  -webkit-appearance: button;
}

button.SearchBar_w-button__81kL4 {
  -webkit-appearance: button;
}

.SearchBar_w-form-done__ilPty {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}
.SearchBar_w-form-fail__gMjCs {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}
.SearchBar_text-field__XO0ZW {
  height: 50px;
  border-radius: 10px;
  margin-bottom: 0;
}

.SearchBar_select-container__yfDFG {
  position: relative;
  width: 100%;
}

.SearchBar_select-container__yfDFG select {
  padding-right: 50px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.SearchBar_select-container__yfDFG i {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 25px;
}

@media screen and (min-width: 1440px) {
  .SearchBar_div-block__7Zk3D {
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .SearchBar_form-block__S_Xs4 {
    width: 100%;
    margin-bottom: 0;
  }

  .SearchBar_form__hN4X4 {
    width: 100%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-items: center;
    display: flex;
  }

  .SearchBar_select-field-2__DFAL_ {
    color: var(--secondary-1);
    flex: 0 1 auto;
    padding-right: 20px;
    font-size: 16px;
  }

  .SearchBar_text-field__XO0ZW {
    min-height: 50px;
    margin-bottom: 0;
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .SearchBar_form-block__S_Xs4 {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 10px;
    padding-top: 20px;
    padding-left: 10px;
  }

  .SearchBar_form__hN4X4 {
    width: 100%;
    flex-direction: column;
    display: flex;
  }

  .SearchBar_select-field-2__DFAL_ {
    width: 100%;
  }

  .SearchBar_w-input__dPBOo:-moz-placeholder,
  .SearchBar_w-select__R2K2I:-moz-placeholder {
    color: #999;
  }
}

.SearchBar_w-input-error__q5g8y {
  border: var(--crimson) solid 2px;
}

.Button_w-button__C2F8q {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}
.Button_button-primary-header__Z_r13 {
  background-color: #024d6d;
  color: white;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 1000px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
  transition: transform 0.3s, background-color 0.3s;
}

.Button_button-primary-header__Z_r13:hover {
  background-color: #0f437f;
  color: white;
  transform: scale3d(0.96, 0.96, 1.01);
}

.Button_button-primary-header__Z_r13.Button_button-2-buttons__172fh {
  background-color: #079ba9;
  margin: 32px 0 20px;
  padding-right: 40px;
}

.Button_button-secondary__1uks_ {
  background-color: var(--neutral-300);
  color: var(--neutral-700);
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 1000px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
  transition: transform 0.3s, background-color 0.3s;
}

.Button_button-secondary__1uks_:hover {
  background-color: var(--neutral-400);
  color: var(--neutral-700);
  transform: scale3d(0.96, 0.96, 1.01);
}
.Button_button-secondary__1uks_.Button_button-2-buttons__172fh {
  color: var(--dark-slate-grey);
  margin-top: 32px;
  margin-bottom: 20px;
  /* margin-right: 68px; */
}

.Button_button-secondary-header__japuV {
  background-color: var(--neutral-300);
  color: var(--neutral-700);
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 1000px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
  transition: transform 0.3s, background-color 0.3s;
}

.Button_button-secondary-header__japuV:hover {
  background-color: var(--neutral-400);
  color: var(--neutral-700);
  transform: scale3d(0.96, 0.96, 1.01);
}
.Button_button-secondary-header__japuV.Button_button-2-buttons__172fh {
  color: var(--dark-slate-grey);
  margin-top: 32px;
  margin-bottom: 20px;
  margin-right: 68px;
}

.Button_button-primary-header__Z_r13.Button_button-white__LQPWt {
  background-color: var(--neutral-100);
  color: var(--neutral-800);
}

.Button_button-primary-header__Z_r13.Button_button-white__LQPWt:hover {
  background-color: var(--secondary-1);
}
.Button_button-primary-header__Z_r13.Button_newsletter__9cpTf {
  padding: 16px 32px;
  position: absolute;
  right: 17px;
}
.Button_button-primary-header__Z_r13.Button_button-2-buttons-copy__H_sdk {
  background-color: var(--cadet-blue);
  margin: 32px 0 20px;
  padding-right: 40px;
}
.Button_submit-button__BI6l9 {
  background-color: var(--secondary-3);
  border-radius: 10px;
}
.Button_button-secondary-header__japuV.Button_hidden__3FO4R.Button_header__0qqDh {
  margin-top: 32px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1440px) {
  .Button_button-primary-header__Z_r13.Button_button-2-buttons__172fh {
    margin-right: 20px;
  }
}

@media screen and (max-width: 991px) {
  .Button_button-primary-header__Z_r13.Button_button-2-buttons__172fh {
    margin-right: 10px;
  }
  .Button_button-secondary-header__japuV.Button_hidden__3FO4R {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .Button_button-primary-header__Z_r13 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .Button_button-primary-header__Z_r13.Button_button-2-buttons__172fh {
    display: block;
  }

  .Button_button-secondary__1uks_ {
    padding-left: 30px;
    padding-right: 30px;
  }

  .Button_button-secondary__1uks_.Button_button-2-buttons__172fh {
    display: block;
  }

  .Button_button-secondary-header__japuV {
    padding-left: 30px;
    padding-right: 30px;
    display: none;
  }

  .Button_button-secondary-header__japuV.Button_button-2-buttons__172fh {
    display: block;
  }

  .Button_button-primary-header__Z_r13.Button_button-2-buttons-copy__H_sdk {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .Button_button-primary-header__Z_r13 {
    padding: 20px 18px;
    display: block;
  }

  .Button_button-primary-header__Z_r13.Button_button-2-buttons__172fh {
    margin-bottom: -22px;
    margin-right: 0;
    display: none;
  }
  .Button_button-secondary__1uks_ {
    padding: 20px 18px;
    display: block;
  }
  .Button_button-secondary__1uks_.Button_button-2-buttons__172fh {
    margin-right: 0;
    display: none;
  }
  .Button_button-secondary-header__japuV {
    padding: 20px 18px;
    display: none;
  }
  .Button_button-secondary-header__japuV.Button_button-2-buttons__172fh {
    margin-right: 0;
    display: none;
  }
  .Button_button-primary-header__Z_r13.Button_newsletter__9cpTf {
    min-height: 65px;
    background-color: var(--neutral-700);
    position: static;
  }
}

@font-face {
  font-family: Rozanovageo;
  src: url(/_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}
@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');

.Story_section__SJIyS {
  padding-top: 80px;
  padding-bottom: 0;
  font-family: 'Kumbh Sans', sans-serif;
  color: var(--neutral-600);

  font-size: 18px;

  /* variables */
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
}
.Story_container-default__JDT_i {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.Story_story-wrapper__LQ1jv {
  justify-content: space-between;
  display: flex;
  position: relative;
}
.Story_split-content__PKGpB.Story_story-left__EU64S {
  z-index: 1;
  max-width: 552px;
  margin-right: 30px;
  position: relative;
}
.Story_split-content__PKGpB.Story_story-right__QLdsU {
  z-index: 1;
  max-width: 552px;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 52px;
  display: flex;
  position: relative;
}
.Story_image-wrapper__OrDdb {
  overflow: hidden;
  transform: translate(0);
}
.Story_image-wrapper__OrDdb.Story_story__Rp2Vu,
.Story_image-wrapper__OrDdb.Story_mission__FpKWJ {
  border-radius: 20px;
}

.Story_image-wrapper__OrDdb.Story_story__Rp2Vu.Story_hidden__dPGmh {
  display: none;
}

.Story_story-content__Mya0u {
  max-width: 537px;
  margin-top: 170px;
}

.Story_story-content__Mya0u {
  max-width: 537px;
  margin-top: 170px;
}

.Story_title__nsLur.Story_story__Rp2Vu {
  color: var(--cadet-blue);
  font-family: 'Rozanovageo', sans-serif;

  font-weight: 600;
  font-size: 38px;
}

.Story_paragraph__jdAJa.Story_about-hero__4iAq1,
.Story_paragraph__jdAJa.Story_achievement__ECkYF,
.Story_paragraph__jdAJa.Story_mission__FpKWJ,
.Story_paragraph__jdAJa.Story_story__Rp2Vu {
  margin-bottom: 0;
}

.Story_mission-content__jNBvx {
  max-width: 537px;
  margin-bottom: 141px;
}
.Story_title__nsLur.Story_mission__FpKWJ {
  max-width: 430px;
  color: var(--cadet-blue);
  font-family: 'Rozanovageo', sans-serif;

  font-weight: 600;
  font-size: 38px;
}

.Story_bg-story-wrapper__epDFb {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.Story_bg__XwK_N.Story_story-shape__H_13I {
  width: 494px;
  min-height: 494px;
  min-width: 494px;
  background-color: var(--secondary-2);
  border-radius: 1000px;
  bottom: 250px;
}

@media screen and (max-width: 991px) {
  .Story_w-container__InUy_ {
    max-width: 728px;
  }
  .Story_container-default__JDT_i {
    flex-direction: column;
    align-items: stretch;
  }

  .Story_story-wrapper__LQ1jv {
    flex-direction: column-reverse;
    align-items: center;
  }
  .Story_split-content__PKGpB.Story_story-left__EU64S {
    max-width: 754px;
    flex-direction: column-reverse;
    align-items: center;
    margin-right: 0;
    display: flex;
  }
  .Story_split-content__PKGpB.Story_story-right__QLdsU {
    max-width: 754px;
    align-items: center;
    margin-top: 0;
    margin-bottom: 100px;
  }

  .Story_story-content__Mya0u {
    text-align: center;
    margin-top: 0;
    margin-bottom: 50px;
  }
  .Story_mission-content__jNBvx {
    text-align: center;
    margin-bottom: 50px;
  }
  .Story_title__nsLur.Story_mission__FpKWJ {
    margin-left: auto;
    margin-right: auto;
  }
  .Story_bg-story-wrapper__epDFb {
    display: none;
  }
  .Story_bg__XwK_N.Story_story-shape__H_13I {
    bottom: auto;
  }
}

@media screen and (max-width: 767px) {
  .Story_section__SJIyS {
    padding-top: 118px;
    padding-bottom: 118px;
  }
  .Story_split-content__PKGpB.Story_story-right__QLdsU {
    margin-bottom: 83px;
  }
  .Story_story-content__Mya0u,
  .Story_mission-content__jNBvx,
  .Story_container-small-568px__0hWvJ.Story_values__JQ_Cv {
    margin-bottom: 40px;
  }
  .Story_title__nsLur.Story_mission__FpKWJ, .Story_title__nsLur.Story_story__Rp2Vu {
    font-size: 32px;
  }
}
@media screen and (max-width: 479px) {
  .Story_w-container__InUy_ {
    max-width: none;
  }
  .Story_container-default__JDT_i {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }

  .Story_split-content__PKGpB.Story_story-right__QLdsU {
    margin-bottom: 69px;
  }
  .Story_story-content__Mya0u,
  .Story_mission-content__jNBvx {
    text-align: left;
    margin-bottom: 35px;
  }

  .Story_title__nsLur.Story_mission__FpKWJ {
    max-width: 313px;
    margin-left: 0;
    font-size: 26px;
  }

  .Story_title__nsLur.Story_story__Rp2Vu {
    margin-bottom: 10px;
    font-size: 26px;
  }
}

